.csat-rating {
    text-align: left;
    width: 100%;
    margin: auto;
    padding-top: 1em;

    input[type='radio'] {
        position: fixed;
        top: 0;
        right: 100%;
    }

    label {
        padding: 0.4em;
        float: left;
        cursor: pointer;
    }

    input[type='radio']:checked ~ input + label {
        background: none;
        color: #aaa;
        position: relative;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 70 70'%3e%3cpath fill='white' stroke='grey' stroke-width='2' d='m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z'/%3e%3c/svg%3e");
        background-size: 6rem 4rem;
        height: 1rem;
        width: 2.1rem;
        margin-bottom: 5px;
    }

    input + label {
        background: $great-white;
        color: orange;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 70 70'%3e%3cpath fill='%23ffdd00' stroke='grey' stroke-width='2' d='m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z'/%3e%3c/svg%3e");
        background-size: 6rem 4rem;
        height: 1rem;
        width: 2.1rem;
        margin-bottom: 5px;
    }

    input + label:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    input:checked + label {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    hr {
        clear: both;
        border: 0;
    }

    .rating-box-new {
        margin-left: -10px;
        display: flex;
        align-items: left;
        justify-content: left;
        flex-wrap: wrap;
        width: 450px;
        white-space: nowrap;
    }

    .flex-break {
        flex-basis: 100%;
        height: 0;
    }

    .rating-star-item {
        flex: 0 1 calc(15%);
        min-height: 50px;
    }

    .csat-button {
        margin-top: -50px;
    }

    @media (max-width: 640px) {
        .csat-button {
            margin-top: -20px;
        }
    }
    #rating1,
    #rating2,
    #rating3,
    #rating4,
    #rating5 {
        display: none;
        white-space: nowrap;
        padding-top: 10px;
        padding-left: 24px;
    }

    input[type='radio']:checked#one ~ #rating1 {
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-left: -10px;
    }

    input[type='radio']:checked#two ~ #rating2 {
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-left: -10px;
    }

    input[type='radio']:checked#three ~ #rating3 {
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-left: -10px;
    }

    input[type='radio']:checked#four ~ #rating4 {
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-left: -10px;
    }

    input[type='radio']:checked#five ~ #rating5 {
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-left: -10px;
    }
  }
