.guide-edit-panel {
    border: 1px solid $great-light-grey;
}

.bci {
    &-map {
        &__region {
            width: 197px;
        }

        &__subregion {
            width: 76px;
        }
    }

    &-table-header {
        width: 33.33%;

        @media (min-width: $mobile-size) {
            &__region {
                width: 50%;
            }

            &__stat {
                width: 25%;
            }
        }
    }
}
