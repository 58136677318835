.article-lock {
    background-image: url('/static/images/lock.svg');
    width: 24px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.image-container-lock-overlay {
    @media (max-width: 640px) {
        margin-bottom: 15px;
    }
    width: 100%;
    height: 150px;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    background-color: rgba(33, 36, 39, 0.7);
}

.article-lock-text {
    margin-top: 15px;
}

.article-list-item {

    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid $great-light-grey;

    &:last-of-type {
        margin-bottom: 20px;
    }

    a {
        display: inline-block;
    }


    .trade-show-link {
        text-decoration: underline;
    }

    h3 {
        margin-bottom: 15px;

        @media (min-width: 640px) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }



    p {
        margin-bottom: 5px;
        @media (min-width: 640px) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
        }
    }

    .image-container {
        background-color: #D3D3D3;
        position: relative;
        @media (max-width: 640px) {
            margin-bottom: 15px;
        }
        width: 100%;
        height: 150px;
        margin: 0px;
    }

    .inner-header div:first {
        height: 0px;
    }

  }


.article-list-item-vertical {

    margin-bottom: 15px;
    padding: 15px;

    a {
        text-decoration: none;
    }

    p {
        margin-bottom: 5px;
        color: #1d70b8;
        @media (min-width: 640px) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
        }
    }

    .image-container {
        position: relative;
        @media (max-width: 640px) {
            margin-bottom: 15px;
        }
        width: 100%;
        height: 150px;
    }

    .inner-header div:first {
        height: 0px;
    }

}
