.salary-rent-component {
    background-color: $great-lighter-grey;

    .govuk-tabs__list-item {
        background-color: $great-white;
    }

    .govuk-tabs__panel {
        background-color: $great-white;
    }

    .govuk-form-group {
        margin-bottom: 10px;
    }

    .salary-rent-component-body {
        background-color: $great-white;

        .source-text {
            margin-top: 10px;
            margin-bottom: 0px;
        }
    }
}
